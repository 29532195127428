export const TableConstant = {
  DEFAULTERR: "sorry unable to process! please try again later",

  GETTABLELISTSUCCESS: "GET_TABLE_LIST_SUCCESS",
  GETTABLELISTFAIL: "GET_TABLE_LIST_FAIL",

  SAVETABLESUCCESS: "SAVE_TABLE_SUCCESS",
  SAVETABLEFAIL: "SAVE_TABLE_FAIL",

  CHANGETABLESTATUSSUCCESS: "CHANGE_TABLE_STATUS_SUCCESS",
  CHANGETABLESTATUSFAIL: "CHANGE_TABLE_STATUS_FAIL",

  DELETETABLESUCCESS: "DELETE_TABLE_SUCCESS",
  DELETETABLEFAIL: "DELETE_TABLE_FAIL",
  TABLE_LOADING: "TABLE_LOADING",
  SET_SELECTED_TABLES: "SET_SELECTED_TABLES",
  CLEAR_TYPE: "CLEAR_TYPE",
};
