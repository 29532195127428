import { DashboardConstant } from "../constant";

const initialState = {
  payload: null,
  type: "",
  message: "",
  campaignCounts: {},
};

export default function dashboardReducers(state = initialState, action) {
  switch (action.type) {
    case DashboardConstant.GETCAMPAIGNSTOTALCOUNTSSUCCESS:
      return {
        ...state,
        campaignCounts: action.payload,
        type: action.type,
        message: action.message,
        description: action.description,
      };
    case DashboardConstant.GETCAMPAIGNSTOTALCOUNTSFAIL:
      return {
        ...state,
        payload: action.payload,
        type: action.type,
        message: action.message,
        description: action.description,
      };
    case DashboardConstant.GETLINECHARTDATASUCCESS:
      return {
        ...state,
        payload: action.payload,
        type: action.type,
        message: action.message,
        description: action.description,
      };
    case DashboardConstant.GETLINECHARTDATAFAIL:
      return {
        ...state,
        payload: action.payload,
        type: action.type,
        message: action.message,
        description: action.description,
      };
    default:
      return state;
  }
}
