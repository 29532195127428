export const AttributeConstant = {
  DEFAULTERR: "sorry unable to process! please try again later",
  CLEAR_TYPE: "CLEAR_TYPE",
  GETATTRIBUTELISTSUCCESS: "GET_ATTRIBUTE_LIST_SUCCESS",
  GETATTRIBUTELISTFAIL: "GET_ATTRIBUTE_LIST_FAIL",

  SAVEATTRIBUTESUCCESS: "SAVE_ATTRIBUTE_SUCCESS",
  SAVEATTRIBUTEFAIL: "SAVE_ATTRIBUTE_FAIL",

  CHANGEATTRIBUTESTATUSSUCCESS: "CHANGE_ATTRIBUTE_STATUS_SUCCESS",
  CHANGEATTRIBUTESTATUSFAIL: "CHANGE_ATTRIBUTE_STATUS_FAIL",

  DELETEATTRIBUTESUCCESS: "DELETE_ATTRIBUTE_SUCCESS",
  DELETEATTRIBUTEFAIL: "DELETE_ATTRIBUTE_FAIL",
  ADD_MUTIPLE_INPUT: "ADD_MUTIPLE_INPUT",
  ADD_MUTIPLE_LIST_INPUT: "ADD_MUTIPLE_LIST_INPUT",
};
