import { Suspense } from "react";
import ReactDOM from "react-dom/client";
import { Provider } from "react-redux";
import { SDKProvider } from "@contentful/react-apps-toolkit";
import { GlobalStyles } from "@contentful/f36-components";
import LocalhostWarning from "./components/localhostWarning";
import reducers from "./redux/reducers";
import { configureStore } from "@reduxjs/toolkit";
import App from "./app";
import CenterSpinner from "components/loaders/centerSpinner";

const store = configureStore({
  reducer: reducers,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: false,
      immutableCheck: false,
    }),
});
const root = ReactDOM.createRoot(document.getElementById("root"));
if (process.env.NODE_ENV === "development" && window.self === window.top) {
  root.render(<LocalhostWarning />);
} else {
  root.render(
    <Suspense fallback={<CenterSpinner />}>
      <SDKProvider>
        <Provider store={store}>
          <GlobalStyles />
          <App />
        </Provider>
      </SDKProvider>
    </Suspense>
  );
}
