export const CreateCampaignConstant = {
  DEFAULTERR: "sorry unable to process! please try again later",

  SETSELECTEDCHANNELS: "SET_SELECTED_CHANNELS",
  SETAUDIENCES: "SET_AUDIENCES",
  SETSEGMENTS: "SET_SEGMENTS",

  ADDAUDIENCE: "ADD_AUDIENCE",
  UPDATEAUDIENCE: "UPDATE_AUDIENCE",
  REMOVEAUDIENCE: "REMOVE_AUDIENCE",

  ADDSEGMENT: "ADD_SEGMENT",
  UPDATESEGMENT: "UPDATE_SEGMENT",
  REMOVESEGMENT: "REMOVE_SEGMENT",

  SAVECONTENTMAPPING: "SAVE_CONTENT_MAPPING",

  SETOPENEDCAMPAIGN: "SET_OPENED_CAMPAIGN",
  SETOPENEDCHANNEL: "SET_OPENED_CHANNEL",
  SETOPENEDAUDIENCE: "SET_OPENED_AUDIENCE",
  SETOPENEDSEGMENT: "SET_OPENED_SEGMENT",
  SETOPENEDCAMPAIGNENDDATE: "SET_OPENED_CAMPAIGN_ENDDATE",
  SETOPENEDCAMPAIGNSTARTDATE: "SET_OPENED_CAMPAIGN_STARTDATE",

  CLEARALL: "CLEAR_ALL",
  ADD_EXPERIENCE_KEYS: "ADD_EXPERIENCE_KEYS",
};
