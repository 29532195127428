import { AttributeConstant } from "../constant";
const initialState = {
  payload: null,
  type: "",
  message: "",
  description: "",
  count: 0,
  mulltipleInputValues: [],
  mulltipleInputListValues: [],
};

export default function attributeReducers(state = initialState, action) {
  switch (action.type) {
    case AttributeConstant.GETATTRIBUTELISTSUCCESS:
      return {
        payload: action.payload,
        type: action.type,
        message: action.message,
        count: action.count,
        description: action.description,
        mulltipleInputValues: state?.mulltipleInputValues,
        mulltipleInputListValues: state?.mulltipleInputListValues,
      };
    case AttributeConstant.GETATTRIBUTELISTFAIL:
      return {
        payload: action.payload,
        type: action.type,
        message: action.message,
        description: action.description,
        mulltipleInputValues: state?.mulltipleInputValues,
        mulltipleInputListValues: state?.mulltipleInputListValues,
      };
    case AttributeConstant.SAVEATTRIBUTESUCCESS:
      return {
        payload: action.payload,
        type: action.type,
        message: action.message,
        description: action.description,
        mulltipleInputValues: state?.mulltipleInputValues,
        mulltipleInputListValues: state?.mulltipleInputListValues,
      };
    case AttributeConstant.SAVEATTRIBUTEFAIL:
      return {
        payload: action.payload,
        type: action.type,
        message: action.message,
        description: action.description,
        mulltipleInputValues: state?.mulltipleInputValues,
        mulltipleInputListValues: state?.mulltipleInputListValues,
      };
    case AttributeConstant.CHANGEATTRIBUTESTATUSSUCCESS:
      return {
        payload: action.payload,
        type: action.type,
        message: action.message,
        description: action.description,
        mulltipleInputValues: state?.mulltipleInputValues,
        mulltipleInputListValues: state?.mulltipleInputListValues,
      };
    case AttributeConstant.CHANGEATTRIBUTESTATUSFAIL:
      return {
        payload: action.payload,
        type: action.type,
        message: action.message,
        description: action.description,
        mulltipleInputValues: state?.mulltipleInputValues,
        mulltipleInputListValues: state?.mulltipleInputListValues,
      };
    case AttributeConstant.ADD_MUTIPLE_INPUT:
      return {
        ...state,
        mulltipleInputValues: action.payload,
      };
    case AttributeConstant.ADD_MUTIPLE_LIST_INPUT:
      return {
        ...state,
        mulltipleInputListValues: action.payload,
      };
    case AttributeConstant.CLEAR_TYPE:
      return {
        ...state,
        type: "",
      };
    default:
      return state;
  }
}
