export const AuthConstant = {
  DEFAULTERR: "sorry unable to process! please try again later",
  LOGINSUCCESS: "LOGIN_SUCCESS",
  LOGINFAIL: "LOGIN_FAIL",

  REGISTERSUCCESS: "REGISTER_SUCCESS",
  REGISTERFAIL: "REGISTER_FAIL",

  VERIFYSUCCESS: "VERIFY_SUCCESS",
  VERIFYFAIL: "VERIFY_FAIL",

  INVALID_TOKEN: "INVALID_TOKEN",
  SET_403_ERROR_COUNT: "SET_403_ERROR_COUNT",
  SET_403_ERROR_COUNT_0: "SET_403_ERROR_COUNT_0",
};
