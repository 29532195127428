export const OrganizationConstant = {
  DEFAULTERR: "sorry unable to process! please try again later",

  GETORGANIZATIONDETAILSUCCESS: "GET_ORGANIZATION_DETAIL_SUCCESS",
  GETORGANIZATIONDETAILFAIL: "GET_ORGANIZATION_DETAIL_FAIL",

  SAVEORGANIZATIONDETAILSUCCESS: "SAVE_ORGANIZATION_DETAIL_SUCCESS",
  SAVEORGANIZATIONDETAILFAIL: "SAVE_ORGANIZATION_DETAIL_FAIL",

  GETORGANIZATIONROLESSUCCESS: "GET_ORGANIZATION_ROLES_SUCCESS",
  GETORGANIZATIONROLESFAIL: "GET_ORGANIZATION_ROLES_FAIL",

  SAVEORGANIZATIONROLESUCCESS: "SAVE_ORGANIZATION_ROLE_SUCCESS",
  SAVEORGANIZATIONROLEFAIL: "SAVE_ORGANIZATION_ROLE_FAIL",

  DELETEORGANIZATIONROLESUCCESS: "DELETE_ORGANIZATION_ROLE_SUCCESS",
  DELETEORGANIZATIONROLEFAIL: "DELETE_ORGANIZATION_ROLE_FAIL",

  GET_ORGANIZATION_INFO_SUCCESS: "GET_ORGANIZATION_INFO_SUCCESS",
  GET_ORGANIZATION_INFO_FAIL: "GET_ORGANIZATION_INFO_FAIL",
  UPDATE_ORGANIZATION_INFO_SUCCESS: "UPDATE_ORGANIZATION_INFO_SUCCESS",
  UPDATE_ORGANIZATION_INFO_FAIL: "UPDATE_ORGANIZATION_INFO_FAIL",
};
