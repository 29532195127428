import { ConfigurationConstant } from "../constant/configurationConstant";
const initialState = {
  payload: null,
  type: "",
  message: "",
  description: "",
  loading: false,
};

export default function configurationReducers(state = initialState, action) {
  switch (action.type) {
    case ConfigurationConstant.GETCONFIGURATIONSUCCESS:
      return {
        payload: action.payload,
        type: action.type,
        message: action.message,
        description: action.description,
        loading: false,
      };
    case ConfigurationConstant.GETCONFIGURATIONFAIL:
      return {
        payload: action.payload,
        type: action.type,
        message: action.message,
        description: action.description,
        loading: false,
      };
    case ConfigurationConstant.SAVECONFIGURATIONSUCCESS:
      return {
        payload: action.payload,
        type: action.type,
        message: action.message,
        description: action.description,
        loading: false,
      };
    case ConfigurationConstant.SAVECONFIGURATIONFAIL:
      return {
        payload: action.payload,
        type: action.type,
        message: action.message,
        description: action.description,
        loading: false,
      };
    case ConfigurationConstant.SET_TYPE_EMPTY:
      return {
        ...state,
        type: "",
      };
    case ConfigurationConstant.SET_LOADING_TRUE:
      return {
        ...state,
        loading: true,
      };
    case ConfigurationConstant.SET_LOADING_FALSE:
      return {
        ...state,
        loading: false,
      };
    default:
      return state;
  }
}
