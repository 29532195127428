import { fieldConstant } from "../constant";
import { v4 as uuidv4 } from "uuid";

const initialState = {
  isIndPersonalization: false,
  isContentABTesting: false,
  panel: "summary",
  isIndOrAbt: "PERSONALIZATION",
  defaultTraffic: 100,
  general: null,
  experiences: [],
  segments: [],
  abVariantDetails: [],
  segmentRules: {},
  draftVariants: [],
  // audiences: [],
  // segments: [],
  // selectedChannels: [],
  // openedChannel: "",
  // openedCampaign: "",
  // openedAudience: "",
  // openedSegment: "",
  // openedCampaignEndDate: "",
  // openedCampaignStartDate: "",
};

let tmpSegments = [];
let tmpSegment;

export default function fieldCreateCampaignReducers(
  state = initialState,
  action
) {
  switch (action.type) {
    case fieldConstant.SETGENERAL:
      return {
        ...state,
        general: action.general,
      };
    case fieldConstant.SETPANEL:
      return {
        ...state,
        panel: action.panel,
      };
    case fieldConstant.SETISINDORABT:
      return {
        ...state,
        isIndOrAbt: action.isIndOrAbt,
      };
    case fieldConstant.SETDEFAULTTRAFFIC:
      return {
        ...state,
        defaultTraffic: action.defaultTraffic,
      };
    case fieldConstant.SETINDEPENDENTPERSONALIZATION:
      return {
        ...state,
        isIndPersonalization: action.isIndPersonalization,
      };
    case fieldConstant.SETCONTENTABTESTING:
      return {
        ...state,
        isContentABTesting: action.isContentABTesting,
      };

    case fieldConstant.ADDEXPERIENCE:
      return {
        ...state,
        experiences: [
          { ...action.payload, id: uuidv4() },
          ...state.experiences,
        ],
      };

    case fieldConstant.ADDABCONTENT:
      return {
        ...state,
        abVariantDetails: action.payload,
      };

    case fieldConstant.ADDFEILDSEGMENT:
      let newSegments = [...state.segments];

      if (action.payload?.id) {
        let index = newSegments.findIndex((e) => e.id === action.payload?.id);
        if (index > -1) {
          newSegments[index] = action.payload;
        } else {
          newSegments = [
            { ...action.payload, id: uuidv4() },
            ...state.segments,
          ];
        }
      } else {
        newSegments = [{ ...action.payload, id: uuidv4() }, ...state.segments];
      }

      return {
        ...state,
        segments: newSegments,
      };
    case fieldConstant.UPDATEFEILDSEGMENT:
      let findIndex = state.segments.findIndex(
        (ele) => ele?.id == action?.payload?.id
      );

      let newSegment = [...state.segments];

      if (findIndex > -1) {
        newSegment[findIndex] = action?.payload;
      } else {
        newSegment.push(action?.payload);
      }

      return {
        ...state,
        segments: newSegment,
      };
    case fieldConstant.DELETEENTRYFROMSEGMENT:
      tmpSegment = { ...state.segments.find((seg) => seg.id == action.key) };
      tmpSegments = [
        { ...tmpSegment, content: null },
        ...state.segments.filter((ele) => ele?.id != action?.key),
      ];
      return {
        ...state,
        segments: tmpSegments,
      };

    case fieldConstant.SETABTESTTRAFFICFORSEGMENT:
      tmpSegment = { ...state.segments.find((seg) => seg.id == action.key) };
      tmpSegment.content.trafficValue = action.trafficValue;
      tmpSegments = [
        { ...tmpSegment },
        ...state.segments.filter((ele) => ele?.id != action?.key),
      ];
      return {
        ...state,
        segments: tmpSegments,
      };
    // case fieldConstant.UPDATEEXPERIENCE:
    //   tmpAudiences = [
    //     { ...action.payload },
    //     ...state.audiences.filter((ele) => ele?.id != action?.payload?.id),
    //   ];
    //   return {
    //     ...state,
    //     audiences: tmpAudiences,
    //   };
    // case fieldConstant.REMOVEEXPERIENCE:
    //   return {
    //     ...state,
    //     audiences: state.audiences.filter((ele) => ele?.id != action.key),
    //     segments: state.segments?.filter((ele) => ele?.audience != action?.key),
    //   };

    case fieldConstant.CLEARFIELDALL:
      return {
        ...state,
        isIndPersonalization: false,
        isContentABTesting: false,
        panel: "summary",
        // route: "/",
        defaultTraffic: 100,
        // type: "",
        general: null,
        experiences: [],
        segments: [],
        abVariantDetails: [],
        generalData: {},
        // audiences: [],
        // segments: [],
        // selectedChannels: [],
        // openedChannel: "",
        // openedCampaign: "",
        // openedAudience: "",
        // openedSegment: "",
        // openedCampaignEndDate: "",
        // openedCampaignStartDate: "",
      };
    case fieldConstant.SETSEGMENTLIST:
      return {
        ...state,
        segments: action.payload,
      };
    case fieldConstant.SETSEGMENTRULE:
      return {
        ...state,
        segmentRules: action.payload,
      };
    case fieldConstant.DRAFTVARIENTS:
      return {
        ...state,
        draftVariants: action.payload?.variants,
        isIndOrAbt: action?.payload?.isIndOrAbt,
        generalData: action?.payload?.generalData,
      };
    default:
      return state;
  }
}
