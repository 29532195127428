import { combineReducers } from "redux";
import alertReducers from "./alertReducers";
import authReducers from "./authReducers";
import attributeReducers from "./attributeReducers";
import channelReducers from "./channelReducers";
import audienceReducers from "./audienceReducers";
import segmentReducers from "./segmentReducers";
import campaignReducers from "./campaignReducers";
import tableReducers from "./tableReducers";
import createCampaignReducers from "./createCampaignReducers";
import dashboardReducers from "./dashboardReducers";
import configurationReducers from "./configurationReducers";
import fieldCreateCampaignReducers from "./fieldCreateCampaignReducers";
import organizationReducers from "./organizationReducers";
import eventsReducers from "./eventReducers";
import contentReducer from "./contentReducer";

export default combineReducers({
  alertNotification: alertReducers,
  auth: authReducers,
  attribute: attributeReducers,
  channel: channelReducers,
  audience: audienceReducers,
  segment: segmentReducers,
  campaign: campaignReducers,
  table: tableReducers,
  createCampaign: createCampaignReducers,
  dashboard: dashboardReducers,
  configuration: configurationReducers,
  fieldCreateCampaign: fieldCreateCampaignReducers,
  organization: organizationReducers,
  events: eventsReducers,
  content: contentReducer,
});
