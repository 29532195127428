import { AudienceConstant } from "../constant";
const initialState = {
  payload: null,
  type: "",
  message: "",
  description: "",
  count: 0,
};

export default function audienceReducers(state = initialState, action) {
  switch (action.type) {
    case AudienceConstant.CLEAR_TYPE:
      return {
        ...state,
        type: "",
      };
    case AudienceConstant.GETAUDIENCELISTSUCCESS:
      return {
        payload: action.payload,
        type: action.type,
        message: action.message,
        count: action.count,
        description: action.description,
      };
    case AudienceConstant.GETAUDIENCELISTFAIL:
      return {
        payload: action.payload,
        type: action.type,
        message: action.message,
        count: action.count,
        description: action.description,
      };
    case AudienceConstant.SAVEAUDIENCESUCCESS:
      return {
        payload: action.payload,
        type: action.type,
        message: action.message,
        description: action.description,
      };
    case AudienceConstant.SAVEAUDIENCEFAIL:
      return {
        payload: action.payload,
        type: action.type,
        message: action.message,
        description: action.description,
      };
    case AudienceConstant.DELETEAUDIENCESUCCESS:
      return {
        payload: action.payload,
        type: action.type,
        message: action.message,
        description: action.description,
      };
    case AudienceConstant.DELETEAUDIENCEFAIL:
      return {
        payload: action.payload,
        type: action.type,
        message: action.message,
        description: action.description,
      };
    case AudienceConstant.INVALID_TOKEN:
      return {
        type: action.type,
        message: action.message,
      };
    default:
      return state;
  }
}
