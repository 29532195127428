export const CampaignConstant = {
  CLEAR_TYPE: "CLEAR_TYPE",
  DEFAULTERR: "sorry unable to process! please try again later",
  SET_LOADING_TRUE: "SET_LOADING_TRUE",
  SET_LOADING_FALSE: "SET_LOADING_FALSE",

  GETCAMPAIGNLISTSUCCESS: "GET_CAMPAIGN_LIST_SUCCESS",
  GETCAMPAIGNLISTFAIL: "GET_CAMPAIGN_LIST_FAIL",

  SAVECAMPAIGNSUCCESS: "SAVE_CAMPAIGN_SUCCESS",
  UPDATECAMPAIGNSUCCESS: "UPDATE_CAMPAIGN_SUCCESS",
  SAVECAMPAIGNFAIL: "SAVE_CAMPAIGN_FAIL",

  DELETECAMPAIGNSUCCESS: "DELETE_CAMPAIGN_SUCCESS",
  DELETECAMPAIGNFAIL: "DELETE_CAMPAIGN_FAIL",

  CHANGECAMPAIGNPRIORITYSUCCESS: "CHANGE_CAMPAIGN_PRIORITY_SUCCESS",
  CHANGECAMPAIGNPRIORITYFAIL: "CHANGE_CAMPAIGN_PRIORITY_FAIL",

  SAVECONTENTABTESTCAMPAIGNSUCCESS: "SAVE_CONTENT_AB_TEST_CAMPAIGN_SUCCESS",
  SAVECONTENTABTESTCAMPAIGNFAIL: "SAVE_CONTENT_AB_TEST_CAMPAIGN_FAIL",

  GET_CONTENT_EXPERINCE_SUCCESS: "GET_CONTENT_EXPERINCE_SUCCESS",
  GET_CONTENT_EXPERINCE_FAIL: "GET_CONTENT_EXPERINCE_FAIL",

  GET_CONTENT_PERSONALIZATION_SUCCESS: "GET_CONTENT_PERSONALIZATION_SUCCESS",
  GET_CONTENT_PERSONALIZATION_COPY_SUCCESS:
    "GET_CONTENT_PERSONALIZATION_COPY_SUCCESS",
  GET_CONTENT_PERSONALIZATION_FAIL: "GET_CONTENT_PERSONALIZATION_FAIL",

  SAVE_CONTENT_PERSONALIZATION_CAMPAIGN_SUCCESS:
    "SAVE_CONTENT_PERSONALIZATION_CAMPAIGN_SUCCESS",
  SAVE_CONTENT_PERSONALIZATION_CAMPAIGN_FAIL:
    "SAVE_CONTENT_PERSONALIZATION_CAMPAIGN_FAIL",
  DELETE_CONTENT_SUCCESS: "DELETE_CONTENT_SUCCESS",
  DELETE_CONTENT_FAIL: "DELETE_CONTENT_FAIL",
  CLEAR_DATA: "CLEAR_DATA",
  UPDATE_CAMPAIGN_STATUS_SUCCESS: "UPDATE_CAMPAIGN_STATUS_SUCCESS",
  UPDATE_CAMPAIGN_STATUS_FAIL: "UPDATE_CAMPAIGN_STATUS_FAIL",
  UPDATE_CONTENT_STATUS_SUCCESS: "UPDATE_CONTENT_STATUS_SUCCESS",
  UPDATE_CONTENT_STATUS_FAIL: "UPDATE_CONTENT_STATUS_FAIL",
  GET_CONTENT_EXPERINCE_COPY_SUCCESS: "GET_CONTENT_EXPERINCE_COPY_SUCCESS",
  CLEAR_ALL_DATA: "CLEAR_ALL_DATA",
};
