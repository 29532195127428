import { Box, Flex, Spinner } from "@contentful/f36-components";

const CenterSpinner = (props) => {
  const { variant, size, autoSize } = props;

  return (
    <Flex
      justifyContent="center"
      style={{
        height: `${!autoSize && "90vh"}`,
        width: `${!autoSize && "90vw"}`,
      }}
    >
      <Box
        style={{
          margin: "auto",
        }}
      >
        <Spinner
          variant={variant ? variant : "primary"}
          size={size ? size : "medium"}
        />
      </Box>
    </Flex>
  );
};

export default CenterSpinner;
