import {
  AlertConstant
} from '../constant';
const initialState = {
    payload : null,
    type:'',
    error : ""
}

export default function alertReducers(state = initialState, action) {
    switch (action.type) {
        case AlertConstant.NOTIFICATIONSUCCESS:
          return {
            type:action.type,
            payload: action.payload,
            notificationType: action.notificationType
          }; 
        case AlertConstant.NOTIFICATIONCLEAR:
          return {
            type:action.type,
            payload: null,
            notificationType: null
          };    
        default:
          return state
      }
}