export const ChannelConstant = {
  DEFAULTERR: "sorry unable to process! please try again later",

  GETCHANNELLISTSUCCESS: "GET_CHANNEL_LIST_SUCCESS",
  GETCHANNELLISTFAIL: "GET_CHANNEL_LIST_FAIL",

  SAVECHANNELSUCCESS: "SAVE_CHANNEL_SUCCESS",
  SAVECHANNELFAIL: "SAVE_CHANNEL_FAIL",

  CHANGECHANNELSTATUSSUCCESS: "CHANGE_CHANNEL_STATUS_SUCCESS",
  CHANGECHANNELSTATUSFAIL: "CHANGE_CHANNEL_STATUS_FAIL",

  DELETECHANNELSUCCESS: "DELETE_CHANNEL_SUCCESS",
  DELETECHANNELFAIL: "DELETE_CHANNEL_FAIL",

  CHANNELINPUTFIELD: "CHANNELINPUTFIELD",
  CLEAR_TYPE: "CLEAR_TYPE",
};
