import { ContentConstant } from "../constant";
const initialState = {
  payload: null,
  type: "",
  error: "",
};

export default function contentReducer(state = initialState, action) {
  switch (action.type) {
    default:
      return state;
  }
}
