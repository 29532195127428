import { SegmentConstant } from "../constant";
const initialState = {
  payload: null,
  type: "",
  message: "",
  description: "",
  count: 0,
  isNewRule: false,
};

export default function segmentReducers(state = initialState, action) {
  switch (action.type) {
    case SegmentConstant.GETSEGMENTLISTSUCCESS:
      return {
        payload: action.payload,
        type: action.type,
        count: action.count,
        description: action.description,
        message: action.message,
        isNewRule: state.isNewRule,
      };
    case SegmentConstant.GETSEGMENTLISTFAIL:
      return {
        payload: action.payload,
        type: action.type,
        count: action.count,
        description: action.description,
        message: action.message,
        isNewRule: state.isNewRule,
      };
    case SegmentConstant.SAVESEGMENTSUCCESS:
      return {
        payload: action.payload,
        type: action.type,
        description: action.description,
        message: action.message,
        isNewRule: state.isNewRule,
      };
    case SegmentConstant.SAVESEGMENTFAIL:
      return {
        payload: action.payload,
        type: action.type,
        description: action.description,
        message: action.message,
        isNewRule: state.isNewRule,
      };
    case SegmentConstant.DELETESEGMENTSUCCESS:
      return {
        payload: action.payload,
        type: action.type,
        description: action.description,
        message: action.message,
        isNewRule: state.isNewRule,
      };
    case SegmentConstant.DELETESEGMENTFAIL:
      return {
        payload: action.payload,
        type: action.type,
        description: action.description,
        message: action.message,
        isNewRule: state.isNewRule,
      };
    case SegmentConstant.SET_IS_NEW_RULE:
      return {
        ...state,
        isNewRule: action?.payload,
      };
    case SegmentConstant.CLEAR_TYPE:
      return {
        ...state,
        type: "",
      };

    default:
      return state;
  }
}
