export const AudienceConstant = {
  DEFAULTERR: "sorry unable to process! please try again later",

  GETAUDIENCELISTSUCCESS: "GET_AUDIENCE_LIST_SUCCESS",
  GETAUDIENCELISTFAIL: "GET_AUDIENCE_LIST_FAIL",

  SAVEAUDIENCESUCCESS: "SAVE_AUDIENCE_SUCCESS",
  SAVEAUDIENCEFAIL: "SAVE_AUDIENCE_FAIL",

  DELETEAUDIENCESUCCESS: "DELETE_AUDIENCE_SUCCESS",
  DELETEAUDIENCEFAIL: "DELETE_AUDIENCE_FAIL",
  CLEAR_TYPE: "CLEAR_TYPE",
};
